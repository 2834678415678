import React from "react";
import logo from "../images/cohort.png";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="container-fluid p-0">
      <nav className="navbar navbar-expand-lg h-100">
        <div className="container-fluid">
          <a className="navbar-brand ms-2" href="#">
           <Link to="/"> <img src={logo} alt="Logo" className="logo-img"/></Link>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
  
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse navbar-container" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto  mb-2 mb-lg-0 gy-1 ">
              <li className="nav-item">
                <Link to="/" className="nav-link fs-6">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/product" className="nav-link">
                  Products
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/faq" className="nav-link">
                  FAQ
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
