import React, { useEffect } from "react";
import img1 from "../images/contact.jpg";
import Navbar from "./navbar";
import Footer from "./Footer";
import { useForm } from "react-hook-form";

const Contact = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  const validateMobileRange = (value) => {
    const number = parseInt(value);
    const minValue = 5000000000;
    const maxValue = 9999999999;

    if (isNaN(number) || number < minValue || number > maxValue) {
      return "Mobile Number not in valid range";
    }
    return true;
  };

  return (
    <>
      <Navbar />

      {/* Contact-Header-Section */}
      <div className="about-section">
        <p className="about-header">
          <b>Got A Question? Let's Talk.</b>
        </p>
      </div>

      {/* Contact_info-Section */}

      <div className="row contact-info-section ">
        <div className="col-md-6 p-5">
          <img className="img-fluid" src={img1} alt="" />
        </div>
        <div className="col-md-6 px-5 py-5">
          <h5 className="text-section-header">Contact Us</h5>
          <h3 className="text-heading-title">Get in Touch With Us</h3>
          <p className="content-para contact-para">
            We're excited to hear from you! Whether you have questions about our
            services, want to discuss a potential project, or simply want to
            explore how we can help your business thrive in the digital era, our
            team is here to assist you.
          </p>
          <div className="fw-normal py-1 d-flex contact-align">
            <i className="bi bi-envelope-fill contact-icons "></i>
            <div className=" py-2">
              <span className="ps-4 align-items-center fs-5 content-para location-color ">
                Email Address
              </span>
              <a href="mailto:info@techcohort.com" className="call-text">
                <h6 className="fs-5 ps-4 fw-normal">info@techcohort.com</h6>{" "}
              </a>
            </div>
          </div>
          <div className=" fw-normal py-2 d-flex contact-align">
            <i className="bi bi-telephone-fill contact-icons"></i>
            <div className="ps-4 py-2">
              <span className="fs-5 py-2 content-para location-color">
                Phone Number
              </span>
              <a href="tel:+91-9665077567" className="call-text">
                <h6 className="fs-5 fw-normal ">+91 9665077567</h6>
              </a>
            </div>
          </div>
          <div className=" fw-normal py-2 d-flex contact-align">
            <i className="bi bi-geo-alt-fill contact-icons"></i>
            <div className="ps-4 py-2">
              <span className="fs-5 py-2 content-para location-color">
                Our Location
              </span>
              <h6 className="fs-5 fw-normal">
                Office No 1, First Floor, Bhushan Bhavan, Opp. MCCIA, Tilak
                Road, Swargate, Pune - 411002
              </h6>
            </div>
          </div>
        </div>
      </div>

      {/* Consultancy-Section */}
      <div className="consultancy-section py-5">
        <h3 className="contact-heading-title fs-1 text-center fw-bold py-2 ">
          Fell Free To Drop Us A Line!
        </h3>
        <div className="d-flex justify-content-center">
          <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
            <div className="container">
              <div className="row">
                <div className="col-md-6 my-3 ">
                  <div className="form-group ">
                    <input
                      type="text"
                      {...register("name", { required: true })}
                      className="form-control py-3  input-align"
                      placeholder="Your Name"
                    />
                    <span className="text-danger">
                      {errors.name?.type === "required" && "Name is Required"}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 my-3 ">
                  <div className="form-group">
                    <input
                      type="email"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
                      })}
                      className="form-control  py-3 input-align-2"
                      placeholder="Email"
                    />
                    <span className="text-danger">
                      {errors.email?.type === "required" && "Email is Required"}
                      {errors.email?.type === "pattern" &&
                        "Enter Valid Email ID"}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 my-3 ">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("company", { required: true })}
                      className="form-control  py-3 input-align"
                      placeholder="Company Name"
                    />
                    <span className="text-danger">
                      {errors.company?.type === "required" &&
                        "Company Name is Required"}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 my-3 ">
                  <div className="form-group">
                    <input
                      type="text"
                      {...register("mobile", {
                        required: true,
                        pattern: /^[0-9]{10}$/,
                        message: "Mobile Number Not valid",
                        validate: validateMobileRange,
                      })}
                      className="form-control  input-align-2 py-3"
                      placeholder="Mobile Number"
                    />
                    <span className="text-danger">
                      {errors.mobile?.type === "required" &&
                        "Mobile Number is Required"}
                      {errors.mobile?.type === "pattern" &&
                        "Mobile Number not Valid"}
                      {errors.mobile?.type === "validate" &&
                        "Mobile Number not Valid"}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 my-3">
                  <div className="form-group">
                    <textarea
                      className="form-control mx-auto"
                      placeholder="Type Your Message Here"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 mt-3 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary btn-md fw-semibold"
                  >
                    Get a Free Consultancy
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
