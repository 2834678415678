import React from "react";
import { Route, Routes} from "react-router-dom";
import Home from "./Components/Homepage";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Product from "./Components/Product";
import FAQ from "./Components/FAQ";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@fortawesome/fontawesome-svg-core";
import "@fortawesome/free-solid-svg-icons";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/product" element={<Product />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}
