import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import img2 from "../images/illustrator.jpg";
import img3 from "../images/illustrator1.jpg";
import img4 from "../images/business1.jpg";
import img5 from "../images/code1.jpg";
import img6 from "../images/itconsulting.jpg";
import img7 from "../images/illustrator5.jpg";
import img8 from "../images/custom-app.jpg";
import img9 from "../images/testimonial-img.jpg";
import img10 from "../images/expert.png";
import img11 from "../images/idea.png";
import img12 from "../images/increase.png";
import Navbar from "./navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
export default function Home() {
  return (
    <>
      <Navbar />
      {/* Hero Section */}
      <div className="row px-5">
        <div className="col-md-6 d-flex align-items-center">
          <div>
            <h1 className="h1-hero-heading fs-1  pt-5">
              Efficient, Secure, and Reliable IT Services at Your Fingertips
            </h1>
            <p className="content-para pt-3 pb-3">
              Choose Cohort as your IT solutions partner, and experience the
              power of technology harnessed for your business's success.
            </p>
            <div className=" align-items-center row text-wrap button-div ">
              <div className="col-md-4 my-2">
                <button className="btn btn-primary btn-sm fs-5 fw-semibold rounded-pill px-3">
                  <Link to="/contact" className="contact-btn">
                    Start a Project
                  </Link>
                </button>
              </div>
              <div className="col-md-8 my-2 ">
                <a
                  href="tel:+91-9665077567"
                  className="nav-link d-flex call-div"
                >
                  <i className="bi bi-telephone-fill call-icon-resize "></i>
                  <a className="fs-4 fw-semibold mb-0 align-items-center call-text">
                    <u>Call Us 9665077567</u>
                  </a>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 d-flex justify-content-center align-items-center py-4 px-1">
          <img className="img-fluid" src={img2} alt="" />
        </div>
      </div>

      {/* Card Section */}
      <div className="card-section">
        <div className="row py-5 px-3">
          <div className="col-md-4 gy-4">
            <div className="card">
              <img src={img10} className="icon-size ms-3 mt-4"></img>
              <div className="card-body">
                <p className="card-text card-title fs-4 text-heading-title">
                  Industry Expertise
                </p>
                <p>
                  We stay Up-To-Date with the latest technology trends and offer
                  innovative solution that helps you stay ahead of the
                  competition.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 gy-4">
            <div className="card">
              <img src={img11} className="icon-size ms-3 mt-4"></img>
              <div className="card-body">
                <p className="card-text card-title fs-4 text-heading-title">
                  {" "}
                  Innovative Technology
                </p>
                <p>
                  At our Innovative Solutions platform, we're committed to
                  providing you with insights into the evolving landscape of IT
                  innovation.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 gy-4">
            <div className="card">
              <img src={img12} className="icon-size ms-3 mt-4"></img>

              <div className="card-body ">
                <p className="card-text card-title fs-4 text-heading-title">
                  Scalability
                </p>
                <p>
                  Our solutions are scalable and can grow with your business,
                  ensuring that you get the most value out of your investment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Technology solution */}
      <div className="row technology-section">
        <div className="col-md-6 ">
          <img className="w-75 ms-5" src={img3} alt="" />
        </div>
        <div className="col-md-6 technology-text pt-5">
          <h5 className="text-section-header">Technology Solutions</h5>
          <h3 className="text-heading-title">
            Technology alligned with Your Ambitions
          </h3>
          <p className="content-para">
            At Cohort, we believe in the transformative power of technology to
            bring your ambitious visions to life. Our commitment is to provide
            technology solutions that not only meet your current needs but also
            align with your long-term goals and aspirations.
          </p>
          <div className="fs-5  section-points mt-4">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">
              Ethical and Responsible Technology Development
            </span>
          </div>
          <div className="fs-5  section-points py-1">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Integration and Interoperability</span>
          </div>
          <div className="fs-5  section-points">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Reliable Support and Maintenance</span>
          </div>
        </div>
      </div>

      {/* Software Development */}
      <div className="row software-section py-5">
        <div className="col-md-6 technology-text d-flex flex-column justify-content-center">
          <h5 className="text-section-header">Software Development</h5>
          <h3 className="text-heading-title">
            Building Digital Solutions, Empowering Business
          </h3>
          <p className="content-para">
            We are dedicated to building digital solutions that empower
            businesses to thrive in the modern era. Our passion lies in
            leveraging the potential of technology to drive growth, efficiency
            and innovation for our clients.
          </p>
          <div className="fs-5 section-points mt-4">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Custom Software Development</span>
          </div>
          <div className="fs-5 section-points py-1">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Web Application Development</span>
          </div>
          <div className="fs-5 section-points ">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Mobile App Development</span>
          </div>
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <img className="w-75 mx-auto" src={img7} alt="" />
        </div>
      </div>

      {/* Our Services section */}

      <h3 className="text-section-header text-center">Featured Services</h3>

      <div className="d-flex justify-content-center px-3 pb-5">
        <section className="row our-services">
          <div className="col-md-4 gy-4">
            <div className="card">
              <div className="card-header bg-transparent">
                <img className="w-100 " src={img4} alt="" />
              </div>
              <div className="card-body">
                <h3 className="fs-4 text-heading-title">
                  {" "}
                  Business Collaboration{" "}
                </h3>
                <p className="card-paragraph">
                  We offer a range of collaborative opportunities that foster
                  mutually beneficial relationships and create a win-win
                  scenario for all parties involved with us in the business.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 card-height gy-4">
            <div className="card">
              <div className="card-header bg-transparent">
                <img className="w-100 " src={img5} alt="" />
              </div>
              <div className="card-body">
                <h3 className="fs-4 text-heading-title">
                  {" "}
                  Coding & Designing
                </h3>
                <p className="card-paragraph">
                  Our coding and design section brings together talented
                  developers and creative designers to create seamless and
                  visually captivating experiences for our clients.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 gy-4">
            <div className="card">
              <div>
                <div className="card-header bg-transparent">
                  <img className="w-100" src={img6} alt="" />
                </div>
                <div className="card-body">
                  <h3 className="fs-4 text-heading-title"> IT Consulting </h3>
                  <p className="card-paragraph">
                    Our seasoned IT consultants bring in-depth industry
                    knowledge, technical expertise, and a customer-centric
                    approach to deliver optimized solutions that drive real value.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

     

      {/* Utilization-Section */}
      <div className="row  py-3 px-3 utilization-section">
        <div className="col-md-6">
          <img className="w-75 ms-5" src={img8} alt="" />
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center">
          <h5 className="text-section-header">Custom App Development</h5>

          <h3 className="text-heading-title">
            Tailored Solutions for Your Unique Needs
          </h3>
          <p className="content-para">
            Our custom app development section is dedicated to empowering your
            unique vision and bringing it to life through cutting-edge
            technology solutions
          </p>
          <p className="fs-5 section-points text-color mt-4">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3">Understanding Your Needs</span>
          </p>
          <p className="fs-5 d-flex section-points text-color">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3">Personalized User Experience</span>
          </p>
          <p className="fs-5 section-points text-color">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3">Full-Cycle Development</span>
          </p>
          <p className="fs-5 d-flex section-points text-color">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3">
              Elevate Your Business with Custom Solutions
            </span>
          </p>
        </div>
      </div>


 {/* Button-Contact-Section */}

 <div className="d-flex flex-column text-center contact-section p-5 mt-3">
        <h3 className="first-heading-contact">
          We take care of your technology.
        </h3>
        <h1 className="contact-heading"> Focus on Your Business</h1>
        <div>
          <Link to="/contact" className="contact-btn">
            {" "}
            <button className="btn btn-primary btn-lg mt-3 fs-4 fw-semibold">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
      {/* Testimonial Section */}
      {/* <div className="row px-5 py-5">
       
          <h3 className="text-section-header text-center">Testimonial</h3>
          <h3 className="text-heading-title text-center">What Clients Say ?</h3>
          <div id="carouselExampleDark" class="carousel carousel-dark slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
              <div className="person-section w-100 text-center">
                      <img
                        src="https://softek.radiantthemes.com/wp-content/uploads/2020/10/testi-lady-7.webp"
                        className="person-icon"
                      ></img>
                      <div className="person-subsection  m-0 ps-4 pt-2">
                        <h5 className="title "> Annie Gomez</h5>
                        <span className="job-title ">Web Developer</span>
                      </div>
                    </div>
                <div className="gy-2">
                  <div className="text-section">
                    <p className="content-para pt-4 text-md-center">
                      "Nulla eleifend, lectus eu gravida facilisis, ipsum metus
                      faucibus eros, vitae vulputate nibh libero ac metus.
                      Praesent eget metus pulvinar, eleifend dolor sed, tempus
                      nibh. Lorem ipsum dolor sit amet Fusce vel scelerisque
                      erat facilisis. Donec hendrerit diam sapien, vel
                      scelerisque erat facilisis ut. Fusce sit amet leo ac eros
                      dignissim porta in vel metus."
                    </p>

                    
                  </div>
                </div>
              </div>
              <div class="carousel-item">
              <div className="person-section w-100 text-center">
                      <img
                        src="https://softek.radiantthemes.com/wp-content/uploads/2020/10/testi-lady-7.webp"
                        class="person-icon"
                        alt="..."
                      />

                      <div className="person-subsection  m-0 ps-4 pt-2">
                        <h5 className="title "> Annie Gomez</h5>
                        <span className="job-title ">Web Developer</span>
                      </div>
                    </div>
                <div className=" gy-2">
                  <div className="text-section">
                    <p className="content-para pt-4 text-center">
                      "Nulla eleifend, lectus eu gravida facilisis, ipsum metus
                      faucibus eros, vitae vulputate nibh libero ac
                      metus.Praesent eget metus pulvinar, eleifend dolor sed,
                      tempus nibh. Lorem ipsum dolor sit amet Fusce vel
                      scelerisque erat facilisis. Donec hendrerit diam sapien,
                      vel scelerisque erat facilisis ut. Fusce sit amet leo ac
                      eros dignissim porta in vel metus."
                    </p>

                    
                  </div>
                </div>
              </div>
              <div class="carousel-item">
              <div className="person-section w-100 text-center">
                      <img
                        src="https://softek.radiantthemes.com/wp-content/uploads/2020/10/testi-lady-7.webp"
                        className="person-icon "
                      ></img>
                      <div className="person-subsection  m-0 ps-4 pt-2">
                        <h5 className="title "> Annie Gomez</h5>
                        <span className="job-title ">Web Developer</span>
                      </div>
                    </div>
                <div className="gy-2">
                  <div className="text-section">
                    <p className="content-para pt-4 text-center">
                      "Nulla eleifend, lectus eu gravida facilisis, ipsum metus
                      faucibus eros, vitae vulputate nibh libero ac metus.
                      "Praesent eget metus pulvinar, eleifend dolor sed, tempus
                      nibh. Lorem ipsum dolor sit amet Fusce vel scelerisque
                      erat facilisis. Donec hendrerit diam sapien, vel
                      scelerisque erat facilisis ut. Fusce sit amet leo ac eros
                      dignissim porta in vel metus."
                    </p>

                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <img className="col-md-6 d-sm-none" src={img9} alt="" /> */}
      

      {/* Footer Section */}

      <Footer />
    </>
  );
}

//     </div>
//     <div class="carousel-item">
//       <img src="..." class="d-block w-100" alt="...">
//     </div>
//     <div class="carousel-item">
//       <img src="..." class="d-block w-100" alt="...">
//     </div>
//   </div>
// </div>
