import React, {useEffect} from "react";
import Navbar from "./navbar";
import Footer from "./Footer";
import img1 from "../images/mobile-app.jpg";
import img2 from "../images/webapp.jpg";
import img3 from "../images/crm.jpg";
import img4 from "../images/erp.jpg";
import { Link } from "react-router-dom";


const Product = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
 
  return (
    <>
      <Navbar />

      {/* Header Section */}
      <div className="about-section">
        <p className="about-header">
          <b>Products</b>
        </p>
      </div>

      {/* Mobile App Section */}

      <div className="row  mobile-section">
        <div className="col-md-6 pt-5 px-5">
          <img className="img-fluid" src={img1} alt="" />
        </div>
        <div className="col-md-6 pt-5">
          <h3 className="text-section-header">
            Mobile Application Development
          </h3>
          <h3 className="text-heading-title">
            Innovate in Every Swipe: Elevate Your Business with Our App
            Innovations.
          </h3>
          <p className="content-para">
            In today's fast-paced digital landscape, mobile apps have become the
            cornerstone of modern business success. At Cohort, we specialize in
            creating dynamic, user-centric mobile applications that propel your
            brand forward. With a keen focus on innovation, functionality, and
            design, our expert team of developers transforms your vision into a
            reality.
          </p>
          <div className="fs-5 section-points mt-4">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 ">Cross-Platform Development</span>
          </div>
          <div className="fs-5 section-points py-1">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 ">
              App Analytics and Performance Monitoring
            </span>
          </div>
          <div className="fs-5 section-points">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 ">Enterprise Mobility Solutions</span>
          </div>
        </div>
      </div>
      
      {/* Web App Section */}
      <div className="row web-section">
        <div className="col-md-6 pt-5">
          <h3 className="text-section-header">
            Web Application Development
          </h3>
          <h3 className="text-heading-title">
            Web Applications that Drive Growth: Bridging Technology and Business
            Success.
          </h3>
          <p className="content-para ">
            In today's interconnected world, web applications have become the
            driving force behind seamless user experiences and efficient
            business operations. With a blend of innovative coding and creative
            design, our expert team transforms your ideas into interactive
            digital solutions.
          </p>
          <div className="fs-5 section-points ">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">User-Centered Design</span>
          </div>
          <div className="fs-5 section-points ">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Comprehensive Development</span>
          </div>
          <div className="fs-5 section-points ">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Performance and Security</span>
          </div>
          <div className="fs-5 section-points ">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">SEO Integration</span>
          </div>
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <img className="img-fluid pt-3" src={img2} alt="" />
        </div>
      </div>

      {/* CRM Section */}
      <div className="row mobile-section pt-5">
        <div className="col-md-6 py-3">
          <img className="img-fluid" src={img3} alt="" />
        </div>
        <div className="col-md-6 py-5">
          <h3 className="text-section-header">CRM Development</h3>
          <h3 className="text-heading-title">
            Custom CRM, Lasting Connections: Your Bridge to Customer-Centric
            Excellence
          </h3>
          <p className="content-para">
            At Cohort, we specialize in crafting tailored CRM (Customer
            Relationship Management) solutions that empower your business to
            build, nurture, and leverage customer connections effectively. Our
            comprehensive CRM development services ensure seamless data
            management, insightful analytics, and enhanced customer engagement.
          </p>
          <div className="fs-5 section-points ">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">360-Degree Customer View</span>
          </div>
          <div className="fs-5 section-points py-1">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Enhanced Customer Engagement </span>
          </div>
          <div className="fs-5 section-points ">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Integration Capabilities</span>
          </div>
        </div>
      </div>

      {/* ERP Solution Section */}
      <div className="row web-section pt-5">
        <div className="col-md-6 pt-3">
          <h3 className="text-section-header">ERP Solutions</h3>
          <h3 className="text-heading-title">
            Unifying Resources, Amplifying Results: Experience the Future of ERP
          </h3>
          <p className="content-para">
            In the ever-evolving world of business, staying ahead requires more
            than just managing day-to-day tasks—it requires a strategic approach
            to resource planning and management. We are firm in delivering
            comprehensive ERP (Enterprise Resource Planning) solutions that
            empower organizations to seamlessly integrate, automate, and
            optimize their processes across departments.
          </p>
          <div className="fs-5 section-points">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Cost-Effective Solution</span>
          </div>
          <div className="fs-5 section-points">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Financial Management</span>
          </div>
          <div className="fs-5 section-points">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3 py-2">Quality Control and Compliance</span>
          </div>
          <div className="fs-5  section-points">
            <i className="bi bi-check-circle-fill check-circle"></i>
            <span className="ps-3  py-2">Business Intelligence (BI) Integration</span>
          </div>
        </div>
        <div className="col-md-6 pb-5 pt-3 d-flex align-items-center">
          <img className="img-fluid" src={img4} alt="" />
        </div>
      </div>

      {/* Button Section */}
      <div className="d-flex flex-column text-center contact-section p-5 mt-3">
        <h3 className="first-heading-contact">Looking for Anything Else?</h3>
        <h1 className="contact-heading">Explore Our Products</h1>
        <div>
          <button className="btn btn-primary btn-lg mt-3 fs-4 fw-semibold">
            <Link className="contact-btn" to="/contact">
              Contact Us
            </Link>
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Product;
