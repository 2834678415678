import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "./navbar";
import img2 from "../images/itsolution.jpg";
import img3 from "../images/product.jpg";
import img4 from "../images/vision-img.jpg";

import "../Components/About.css";
import Footer from "./Footer";
const About = () => {
  return (
    <>
      <div className="main">
        {/* Navbar section */}
        <Navbar />

        {/* About section */}
        <div className="about-section">
          <p className="about-header">
            <b>About Us</b>
          </p>
        </div>

        {/* Who-We-Are Section */}

        <div className="info-section py-3">
          <div className="col-md-6 who-we-are pt-5">
            <h6 className="text-section-header">Who We Are</h6>
            <h1 className="text-heading-title ">
              Custom IT Solutions For Your Business
            </h1>
            <p className="content-para">
              We are a boutique digital transformation consultancy and
              development company.
            </p>
            <p className="content-para">
              No two businesses are alike, and neither should their IT solutions
              be. Our seasoned team of experts leverages their extensive
              industry experience and technical acumen to tailor technology to
              your specific requirements. Whether you're looking to streamline
              operations, enhance customer experiences, or scale your
              infrastructure, we've got you covered.
            </p>
            <p className="content-para">
              Whether you need a web application, mobile app, or enterprise
              software, our experienced developers are here to turn your vision
              into reality. With a deep understanding of technology trends and a
              commitment to excellence, we offer a wide range of custom IT
              solutions designed to meet your specific needs.
            </p>
            <p className="content-para">
              Embark on a journey of technological transformation with Cohort.
              Experience the difference that custom IT solutions can make in
              driving efficiency, innovation, and growth. Contact us today to
              discuss your unique needs and let's create a tailored IT solution
              that sets you on the path to success.
            </p>
          </div>
          <img className="col-md-6 " src={img2}></img>
        </div>

        {/* Info-Section-2 */}
        <div className="info-section-2 row pt-5">
          <div className="col-md-6 d-flex align-items-center ">
            <img className="img-fluid mx-auto" src={img3}></img>
          </div>
          <div className="col-md-6  who-we-are">
            <h6 className="text-section-header">What We Do</h6>
            <h1 className="text-heading-title py-2">
              Product Development for Better Business
            </h1>
            <p className="section-points">
              We are priveleged to work with hundreds of future-thinking
              business, including many of the world's top brands and startups.
            </p>
            <p>
              <ul className="ps-3 ul-padding">
                <li>
                  <b className="fs-6 fw-bold">Software Development :</b>
                  <p>
                    Our development team creates bespoke applications that cater
                    to your specific requirements. From user-friendly interfaces
                    to complex backend systems, we deliver software that
                    enhances efficiency and user experience.
                  </p>
                </li>
                <li>
                  <b className="fs-6 fw-bold">Tech Support and Helpdesk :</b>
                  <p>
                    When technical issues arise, we're here to support you. Our
                    responsive helpdesk provides swift solutions to your IT
                    challenges. Whether it's troubleshooting, software support,
                    or user assistance, we ensure minimal disruptions to your
                    operations. Partner with Cohort for reliable, innovative,
                    and scalable IT services. Contact us today to discuss how we
                    can transform your technology landscape.
                  </p>
                </li>
                <li>
                  <b className="fs-6 fw-bold">IT Consultation and Strategy :</b>
                  <p>
                    Guiding your IT decisions towards success, our expert
                    consultants work closely with you to develop comprehensive
                    IT strategies aligned with your business objectives. We
                    analyze your current setup, recommend improvements, and
                    implement solutions that position you for growth.
                  </p>
                </li>
              </ul>
            </p>
          </div>
        </div>

        {/* Statistics-section */}
        <div className="statistics-section px-5 py-5 my-5">
          <div className="statistics-div px-2">
            <h3 className="numbers m-0">100 +</h3>
            <p className="m-0 stat-text">Happy Clients </p>
          </div>
          <div className="statistics-div px-2">
            <h3 className="numbers m-0 ">23 +</h3>
            <p className="m-0 stat-text">Projects Completed </p>
          </div>
          <div className="statistics-div px-2">
            <h3 className="numbers m-0 ">5 +</h3>
            <p className="m-0 stat-text">Awards Winning</p>
          </div>
          <div className="statistics-last-div px-2">
            <h3 className="numbers m-0 ">10 +</h3>
            <p className="m-0 stat-text">Experience</p>
          </div>
        </div>

        {/* Our Vision Section */}
        <div className="vision-section row  py-3">
          <div className="col-md-6 who-we-are  px-auto">
            <h6 className="header-title fs-4 fw-bold">Our Vision</h6>

            <p className="section-points pt-1">
              Welcome to Cohort Technologies, where innovation meets
              reliability. Our comprehensive range of IT solutions is designed
              to empower businesses of all sizes and industries. With a focus on
              cutting-edge technology and personalized strategies, we're
              dedicated to optimizing your digital landscape.
            </p>
            <p className="pt-2 section-points">
              At Cohort, we're not merely providing services – we're
              architecting a future of limitless possibilities. We envision a
              world where technology empowers businesses to achieve
              transformative growth. Through our services, we aim to be the
              driving force behind your organization's evolution, propelling you
              towards unprecedented success.
            </p>
            <p className="pt-2 section-points">
              Our vision is rooted in a relentless pursuit of innovation. We aim
              to continuously explore emerging technologies, embrace disruptive
              trends, and push the boundaries of what's possible.Our vision
              includes fostering collaborative partnerships with our clients. We
              believe in working hand-in-hand, listening to their needs, and
              co-creating solutions that drive meaningful and measurable impact.
            </p>
            <div className="d-flex justify-content-end"><button className="btn btn-primary btn-lg mt-3"><Link to="/contact" className="contact-btn">Contact Us</Link></button></div>

          </div>
          <div className="col-md-6 pt-3 px-5 d-md-block d-none">
            <img className="img-fluid " src={img4}></img>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
