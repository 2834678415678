import React from "react";
import logo from "../images/cohort.png";

export default function Footer() {
  return (
    <>
      <footer className="footer-color">
        <div className="row align-items-center p-5">
          <div className="col-sm-3 gy-4">
            <img src={logo} className="w-50 "></img>
            <p className="footer-p-text pt-2">
              Our mission is to bridge the gap between technology and
              understanding, making innovation accessible to all.{" "}
            </p>
           
          </div>
          <div className="col-sm-3 gy-4">
            <div className="footer-inner-item fs-6 fw-bold">
              Quick Links
            </div>
            <div className="footer-subitem-list">
              <p className="m-0 pt-2">IT Software</p>
              <p className="m-0 pt-2">IT Support</p>
              <p className="m-0 pt-2">Technology</p>
              <p className="m-0 pt-2">Customer Experience</p>
            </div>
          </div>
          <div className="col-md-3 gy-4">
            <div className="footer-inner-item d-flex fs-6 fw-bold ">
              Subscribe
            </div>
            <div className="footer-subitem-list">
              <p className="m-0 pt-2">Help and FAQ</p>
              <p className="m-0 pt-2">Contact Us</p>
              <p className="m-0 pt-2">Pricing and Plans</p>
              <p className="m-0 pt-2">Cookies Policy</p>
            </div>
          </div>
          <div className="col-sm-3 gy-4">
            <div className="footer-inner-item fs-6 fw-bold">
              Contact Us
            </div>
            <div className="">
              <p className="m-0 pt-2">
                Location: Office No 1, First Floor, Bhushan Bhavan, Opp. MCCIA, Tilak Road, Swargate, Pune - 411002
              </p>
              <p className="m-0 pt-2">Email : info@techcohort.com</p>
              <p className="m-0 pt-2">Contact No : +91 9665077567</p>
            </div>
          </div>
        </div>
      </footer>

      <hr className="m-0"></hr>

      <div className=" py-3 text-center">
        <div className="copyright-section">
          @2023 Cohort Tech. All Rights Reserved.{" "}
        </div>
      </div>
    </>
  );
}
