import React from "react";
import Navbar from "./navbar";
import img1 from "../images/faq2.jpg";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const FAQ = () => {
  return (
    <>
      <Navbar />

      {/* FAQ Header Section */}
      {/* Contact-Header-Section */}
      <div className="about-section">
        <p className="about-header">
          <b>FAQ</b>
        </p>
      </div>

      {/* FAQ Main Section */}
      <div className="faq-main-section p-5 row ">
        <div className="col-md-5 p-5 d-flex align-items-center">
          <img className="img-fluid" src={img1} alt="" />
        </div>
        <div className="col-md-7 pt-5 py-3  faq-border gy-2">
          <h3 className="text-heading-title faq-color">
            Frequently Asked Questions
          </h3>
          <p className="pt-4 content-para">
            Welcome to the FAQ page of Cohort Technologies! Here, we've compiled
            a list of frequently asked questions to provide you with quick and
            easy answers to common queries. If you don't find the information
            you're looking for here, please feel free to contact our support
            team for further assistance.
          </p>

          <div class="accordion mt-4" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  1. What services does your IT Solutions Company offer?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                 We provide expertise in managed IT services, data migration to
                  the cloud, industry-specific solutions, cybersecurity
                  measures, and custom software development to meet your
                  business's unique needs.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  2. How do I request a consultation for my IT needs?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  To request a consultation, simply visit our "Contact Us" page
                  and fill out the consultation request form. Our team will get
                  in touch with you to schedule a convenient time for a
                  discussion.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  3. Are your IT solutions tailored to specific industries?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, our IT solutions are designed to cater to various
                  industries, including healthcare, finance, education,
                  manufacturing, and more. We understand that different sectors
                  have unique technology requirements, and our experts can
                  customize solutions to meet your specific needs.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  4. How experienced is your team?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Our team consists of highly skilled IT professionals with
                  extensive experience in their respective fields. We
                  continuously invest in training and staying up-to-date with
                  the latest technological advancements to provide you with the
                  best solutions.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  5. Do you offer managed IT services?
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, we provide managed IT services that allow you to
                  outsource the management and maintenance of your IT
                  infrastructure. This includes proactive monitoring,
                  troubleshooting, and regular updates to ensure optimal
                  performance and security.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  6. Are your IT solutions tailored to specific industries?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Absolutely, we offer custom software development services
                  tailored to your unique business requirements. Our development
                  team will work closely with you to create solutions that
                  enhance your operational efficiency and streamline processes.
                </div>
              </div>
            </div>
          </div>
          <p className="content-para pt-2 m-0">Still have Questions?</p>
          <p className="faq-text fs-6 ">
            Can't find the answers you are looking for. Please contact us for
            further queries.
          </p>
          <button className="btn btn-primary btn-lg mt-2"><Link to="/contact" className="contact-btn">Contact Us</Link></button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
